import TeamSkipLogo from '../Logo/TeamSkipLogo';

interface MenuProps {
  toggleMenu: () => void;
  logout: () => void;
}

const Menu = (props: MenuProps) => (
  <div className="menu-mobile">
    <TeamSkipLogo />
    <button className="close-popup-menu" onClick={props.toggleMenu}>
      ✕
    </button>

    <ul className="menu-items">
      <li>
        <hr />
      </li>
      <li onClick={props.logout}>
        Logout <span>›</span>
      </li>
      <li>
        Send Feedback <span>›</span>
      </li>
      <li>
        <hr />
      </li>
      <li>
        Privacy Policy <span>›</span>
      </li>
      <li>
        Terms of service <span>›</span>
      </li>
    </ul>
  </div>
);

export default Menu;
