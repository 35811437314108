import IFormItem from '../../classes/IFormItem';
import { useEffect, useState } from 'react';
import checkIsValid from '../../util/checkIsValid';
import AlertError from '../../components/Alerts/AlertError';
import Input from '../../components/FormField/Input';
import Button from '../../components/FormField/Button';
import { useNavigate } from 'react-router-dom';
import { IAppState } from '../../classes/IAppState';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as actionCreators from '../../store/actions';
import { connect } from 'react-redux';

interface ISignupForm {
  email: IFormItem;
  password: IFormItem;
}

interface SignupProps {
  error: boolean;
  loading: boolean;
  errorMessage: string;
  signup: (email: string, password: string) => void;
  loggedIn: boolean;
  email: string;
}

const Signup = (props: SignupProps) => {
  const initialSignupForm: ISignupForm = {
    email: {
      elementType: 'input',
      elementConfig: {
        type: 'email',
        placeholder: 'you@email.com',
      },
      value: '',
      label: 'Your email',
      validation: {
        required: true,
        email: true,
      },
      errorMessage: 'Please enter a valid email address',
      valid: false,
      touched: false,
    },
    password: {
      elementType: 'input',
      elementConfig: {
        type: 'password',
        placeholder: 'Password',
      },
      value: '',
      label: 'Password',
      validation: { required: true, minLength: 8 },
      errorMessage: 'Please enter a password of at least 8 characters',
      valid: false,
      touched: false,
    },
  };

  const [signupForm, setSignupForm] = useState(initialSignupForm);
  const [formIsValid, setFormIsValid] = useState(false);
  const [submitted, setSubitted] = useState(false);

  const inputChangeHandler = (event: any, inputId: keyof ISignupForm) => {
    const updatedLoginForm = {
      ...signupForm,
    };

    const updatedElement = {
      ...updatedLoginForm[inputId],
    };

    updatedElement.value = event.target ? event.target.value : event;
    updatedElement.valid = checkIsValid(
      updatedElement.value,
      updatedElement.validation,
    );
    updatedElement.touched = true;

    updatedLoginForm[inputId] = updatedElement;

    let formIsValid = true;
    Object.keys(updatedLoginForm).forEach((key) => {
      formIsValid =
        updatedLoginForm[key as keyof ISignupForm].valid && formIsValid;
    });

    setSignupForm(updatedLoginForm);
    setFormIsValid(formIsValid);
  };

  const { loggedIn } = props;
  const email = localStorage.getItem('email') || '';
  const navigate = useNavigate();
  useEffect(() => {
    if (loggedIn && email.length) {
      navigate(0);
    }
  }, [loggedIn, navigate, email]);

  const signupPressed = () => {
    setSubitted(true);
    if (formIsValid) {
      props.signup(
        signupForm.email.value as string,
        signupForm.password.value as string,
      );
    }
  };

  const formElements: { config: IFormItem; id: string }[] = [];
  Object.keys(signupForm).forEach((key) => {
    formElements.push({
      config: signupForm[key as keyof ISignupForm],
      id: key,
    });
  });

  return (
    <div className="px-2 md:px-12 lg:px-12">
      <h2 className="text-center text-2xl" id="welcome">
        Sign Up.
      </h2>

      {props.error ? (
        <div className="pt-6">
          <AlertError
            message={'Error: ' + props.errorMessage}
            closeHandler={null}
          />
        </div>
      ) : null}

      <form className="flex flex-col pt-3" id="login-form">
        {formElements.map((formElement) => (
          <Input
            elementType={formElement.config.elementType}
            label={formElement.config.label}
            elementConfig={formElement.config.elementConfig}
            key={formElement.id}
            invalid={!formElement.config.valid && submitted}
            shouldValidate={!!formElement.config.validation}
            errorMessage={formElement.config.errorMessage}
            touched={formElement.config.touched}
            handleChange={(event) =>
              inputChangeHandler(event, formElement.id as keyof ISignupForm)
            }
            id={formElement.id}
          />
        ))}
        <div className="mt-8" />
        {!props.loading ? (
          <Button
            id="login-button"
            type="button"
            value="Sign Up"
            action={signupPressed}
          />
        ) : (
          <div className="modal-loading">
            <h2>Loading...</h2>
          </div>
        )}
      </form>
      {/*<div className="text-center pt-12 pb-12">*/}
      {/*  <p>*/}
      {/*    Already have an account?*/}
      {/*    <p className="underline font-semibold">*/}
      {/*      <NavLink to="/login">Log in</NavLink>*/}
      {/*    </p>*/}
      {/*  </p>*/}
      {/*</div>*/}
    </div>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.users.loading,
    email: state.users.email,
    error: state.users.signupError,
    loggedIn: state.users.loggedIn,
    errorMessage: state.users.errorMessage,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    signup: (email: string, password: string) =>
      dispatch(actionCreators.signup(email, password)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Signup);
