import React from 'react';

import Message from './Message/Message';
import IChatDay from '../../classes/IChatDay';
import LoadingMessage from './LoadingMessage/LoadingMessage';

interface MessageProps {
  data: IChatDay[];
  toggleLoginModal: () => void;
  toggleSignupModal: () => void;
  showLoader: boolean;
}

const Messages: Function = (props: MessageProps) => {
  if (
    props.data.length &&
    props.data[0].day === 'Today' &&
    props.data[0].data.length === 0
  ) {
    return (
      <div>
        <Message
          key="1"
          from="SKIP"
          message="Welcome to TeamSkip! Please enter your email address to continue."
          special=""
          timestamp={new Date()}
          setShowLoginModal={props.toggleLoginModal}
          setShowSignupModal={props.toggleSignupModal}
        />
      </div>
    );
  }

  return props.data.map((dayData, index) => {
    if (dayData.data.length) {
      return (
        <div key={index}>
          <span className="day-spacer">{dayData.day}</span>
          {dayData.data.map((messageData, messageIndex) => (
            <Message
              key={messageIndex}
              from={messageData.messageFrom}
              message={messageData.message}
              special={messageData.special}
              timestamp={messageData.timestamp}
              setShowLoginModal={props.toggleLoginModal}
              setShowSignupModal={props.toggleSignupModal}
            />
          ))}
          {dayData.day === 'Today' && props.showLoader ? (
            <LoadingMessage key="loading" />
          ) : null}
        </div>
      );
    }
    return <React.Fragment key={index}></React.Fragment>;
  });
};

export default Messages;
