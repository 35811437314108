interface SuggestionsProps {
  toggleShow: () => void;
  sendMessage: (message: string, autoSend: boolean) => void;
}

interface Suggestion {
  label: string;
  message: string;
  autoSend: boolean;
}

const SUGGESTIONS: Suggestion[] = [
  { label: 'Today', message: 'Tell me about today', autoSend: true },
  {
    label: 'Discuss this week',
    message: 'Tell me about the next 7 days',
    autoSend: true,
  },
  {
    label: 'Set a new objective',
    message: 'Can I set a new objective?',
    autoSend: true,
  },
  {
    label: 'Discuss objective',
    message: 'Tell me about my objective',
    autoSend: true,
  },
  {
    label: 'Discuss training plan',
    message: 'Lets discuss my training plan until my next objective',
    autoSend: true,
  },
  {
    label: 'Personal intro',
    message: 'Do you have all my bio and preferences',
    autoSend: true,
  },
  {
    label: 'Meet your coach, Skip',
    message: 'Skip who are you and what is your training philosophy?',
    autoSend: true,
  },
  {
    label: 'Privacy',
    message: 'I am concerned with the privacy of my data',
    autoSend: true,
  },
  {
    label: 'How planning works',
    message: 'How does Team Skip develop a customer training plan for me?',
    autoSend: true,
  },
  {
    label: 'Submit feedback',
    message: 'Please pass along this comment to the founders: ',
    autoSend: false,
  },
  {
    label: 'Submit race report',
    message: 'Lets talk about a recent race, to put in my journal',
    autoSend: true,
  },
  {
    label: 'Best Skip Feature',
    message: 'What is your best feature?',
    autoSend: true,
  },
];

const Suggestions = (props: SuggestionsProps) => {
  return (
    <>
      <div className="popup-topics-overlay"></div>
      <div className="popup-topics">
        <h2>Suggested Messages</h2>
        <button className="close-popup-topics" onClick={props.toggleShow}>
          ✕
        </button>
        <div className="topics-container">
          {SUGGESTIONS.map((suggestion) => (
            <div
              className="box"
              onClick={() => {
                props.sendMessage(suggestion.message, suggestion.autoSend);
                props.toggleShow();
              }}
            >
              <img src="img-1.jpg" alt="" />
              <h3>{suggestion.label}</h3>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Suggestions;
