import { Dispatch } from 'redux';
import { connectStravaActionTypes } from './actionTypes';
import axios from '../../axios-skip';

const stravaConnectStart = () => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_START,
  };
};

const stravaConnectError = (error: string) => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_ERROR,
    errorMessage: error,
  };
};

interface StravaConnectSuccessResponse {
  something: string;
}

const stravaConnectSuccess = (data: StravaConnectSuccessResponse) => {
  return {
    type: connectStravaActionTypes.CONNECT_STRAVA_SUCCESS,
  };
};

export const stravaConnect = (code: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(stravaConnectStart());
    try {
      const response = await axios.post('/athlete/connect-strava', { code });
      dispatch(stravaConnectSuccess(response.data));
    } catch (e) {
      dispatch(stravaConnectError('There was en error connecting'));
    }
  };
};
