export const userActionTypes = {
  LOGIN_START: 'LOGIN_START',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_ERROR: 'LOGIN_ERROR',
  SIGNUP_START: 'SIGNUP_START',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',
  SIGNUP_ERROR: 'SIGNUP_ERROR',
  LOGOUT: 'LOGOUT',
  UPDATE_USER_INIT: 'UPDATE_USER_INIT',
  UPDATE_USER_START: 'UPDATE_USER_START',
  UPDATE_USER_ERROR: 'UPDATE_USER_ERROR',
  UPDATE_USER_SUCCESS: 'UPDATE_USER_SUCCESS',
  DELETE_USER_INIT: 'DELETE_USER_INIT',
  DELETE_USER_START: 'DELETE_USER_START',
  DELETE_USER_SUCCESS: 'DELETE_USER_SUCCESS',
  DELETE_USER_ERROR: 'DELETE_USER_ERROR',
  GET_USERS_START: 'GET_USERS_START',
  GET_USERS_ERROR: 'GET_USERS_ERROR',
  GET_USERS_SUCCESS: 'GET_USERS_SUCCESS',
  GET_USER_START: 'GET_USER_START',
  GET_USER_ERROR: 'GET_USER_ERROR',
  GET_USER_SUCCESS: 'GET_USER_SUCCESS',
} as const;

export const chatsActionTypes = {
  GET_CHATS_START: 'GET_CHATS_START',
  GET_CHATS_ERROR: 'GET_CHATS_ERROR',
  GET_CHATS_SUCCESS: 'GET_CHATS_SUCCESS',
  SEND_CHAT_START: 'SEND_CHAT_START',
  SEND_CHAT_ERROR: 'SEND_CHAT_ERROR',
  SEND_CHAT_SUCCESS: 'SEND_CHAT_SUCCESS',
};

export const connectStravaActionTypes = {
  CONNECT_STRAVA_START: 'CONNECT_STRAVA_START',
  CONNECT_STRAVA_ERROR: 'CONNECT_STRAVA_ERROR',
  CONNECT_STRAVA_SUCCESS: 'CONNECT_STRAVA_SUCCESS',
};
