import { userActionTypes } from '../actions/actionTypes';

export interface UsersState {
  loggedIn: boolean;
  loading: boolean;
  error: boolean;
  errorMessage: string;
  logInError: boolean;
  signupError: boolean;
  firstName: string;
  lastName: string;
  email: string;
}

const initialState: UsersState = {
  loggedIn: !!localStorage.getItem('accessToken'),
  loading: false,
  error: false,
  errorMessage: '',
  logInError: false,
  signupError: false,
  firstName: localStorage.getItem('firstName') || '',
  lastName: localStorage.getItem('lastName') || '',
  email: localStorage.getItem('email') || '',
};

type Action =
  | { type: typeof userActionTypes.LOGIN_START }
  | { type: typeof userActionTypes.SIGNUP_START }
  | { type: typeof userActionTypes.SIGNUP_SUCCESS }
  | { type: typeof userActionTypes.SIGNUP_ERROR; errorMessage: string }
  | { type: typeof userActionTypes.LOGOUT }
  | {
      type: typeof userActionTypes.LOGIN_SUCCESS;
      first_name: string;
      last_name: string;
      email: string;
    };

const reducer = (state: UsersState = initialState, action: Action) => {
  switch (action.type) {
    case userActionTypes.LOGIN_START:
      return {
        ...state,
        loading: true,
        logInError: false,
        signupError: false,
        errorMessage: '',
        errorCode: null,
      };

    case userActionTypes.SIGNUP_START:
      return {
        ...state,
        loading: true,
        signupError: false,
        errorMessage: '',
        errorCode: null,
      };

    case userActionTypes.LOGIN_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        firstName: action.first_name,
        lastName: action.last_name,
        email: action.email,
      };

    case userActionTypes.SIGNUP_SUCCESS:
      return {
        ...state,
        loading: false,
        loggedIn: true,
      };

    case userActionTypes.SIGNUP_ERROR:
      return {
        ...state,
        loading: false,
        signupError: true,
        errorMessage: action.errorMessage,
      };

    case userActionTypes.LOGOUT:
      return {
        ...state,
        loggedIn: false,
      };

    default:
      return state;
  }
};

export default reducer;
