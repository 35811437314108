interface conf {
  BACKEND_URL: string;
  BACKEND_WS_URL: string;
  STRAVA_CALLBACK_URL: string;
  FRONTEND_URL: string;
}

let localConfig: conf = {
  BACKEND_URL: 'http://localhost:8000/',
  BACKEND_WS_URL: 'ws://localhost:8000/',
  FRONTEND_URL: 'http://localhost:3000/',
  STRAVA_CALLBACK_URL:
    'https://www.strava.com/oauth/authorize?client_id=2021&redirect_uri=http://localhost:3000/stravaCallback&response_type=code&scope=read,activity:read_all',
};

let prodConfig: conf = {
  BACKEND_URL: 'https://api.teamskip.ai/',
  BACKEND_WS_URL: 'wss://api.teamskip.ai/',
  FRONTEND_URL: 'https://teamskip.ai',
  STRAVA_CALLBACK_URL:
    'https://www.strava.com/oauth/authorize?client_id=2021&redirect_uri=https://app.teamskip.ai/stravaCallback&response_type=code&scope=read,activity:read_all',
};

let config = { ...localConfig };
if (process.env.NODE_ENV === 'production') {
  config = { ...prodConfig };
}

export default config;
