import { chatsActionTypes } from './actionTypes';
import { Dispatch } from 'redux';
import axios from '../../axios-skip';
import { IChat, ChatFromBackend, ChatDayFromBackend } from '../reducers/chats';
import IChatDay from '../../classes/IChatDay';

const getChatsStart = () => {
  return {
    type: chatsActionTypes.GET_CHATS_START,
  };
};

// interface GetChatsSuccessResponse {
//   chats: Chat[]
// }

const getChatsSuccess = (data: ChatDayFromBackend[]) => {
  return {
    type: chatsActionTypes.GET_CHATS_SUCCESS,
    chats: data.map((c) => convertToChatDay(c)),
  };
};

const getChatsError = () => {
  return {
    type: chatsActionTypes.GET_CHATS_ERROR,
  };
};

const sendChatStart = () => {
  return {
    type: chatsActionTypes.SEND_CHAT_START,
  };
};

const sendChatError = () => {
  return {
    type: chatsActionTypes.SEND_CHAT_ERROR,
  };
};

interface SendChatResponse {
  response: ChatFromBackend;
  request: ChatFromBackend;
  onboarding_complete: boolean;
  need_strava_connect: boolean;
}

const convertToChat = (chat: ChatFromBackend): IChat => {
  return {
    id: chat.id,
    message: chat.message,
    messageFrom: chat.message_from,
    timestamp: chat.timestamp,
    type: chat.type,
  };
};

const convertToChatDay = (chatDay: ChatDayFromBackend): IChatDay => {
  return {
    day: chatDay.day,
    data: chatDay.data.map((c) => convertToChat(c)),
  };
};

const sendChatSuccess = (message: SendChatResponse) => {
  return {
    type: chatsActionTypes.SEND_CHAT_SUCCESS,
    chats: [convertToChat(message.request), convertToChat(message.response)],
  };
};

export const getChats = () => {
  return async (dispatch: Dispatch) => {
    dispatch(getChatsStart());
    try {
      const response = await axios.get('chats');
      dispatch(getChatsSuccess(response.data));
    } catch (e) {
      dispatch(getChatsError());
    }
  };
};

export const sendChat = (message: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(sendChatStart());
    try {
      const response = await axios.post('chats', { message: message });
      dispatch(sendChatSuccess(response.data));
    } catch (e) {
      dispatch(sendChatError());
    }
  };
};
