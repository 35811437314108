import Login from '../../containers/Login/Login';
import Signup from '../../containers/Signup/Signup';

interface ModalProps {
  type: 'LOGIN' | 'SIGNUP';
}

const Modal = (props: ModalProps) => (
  <div className="modal-wrapper">
    <div className="popup popup-login">
      {/*<button className="popup-close" onClick={() => {}}>✕</button>*/}
      <div className="popup-content">
        {props.type === 'LOGIN' ? <Login /> : <Signup />}
        {/*<h2>Login</h2>*/}
        {/*<form>*/}
        {/*    <div className="form-group">*/}
        {/*        <label>Email</label>*/}
        {/*        <input type="text" name="email" autoComplete="current-email" />*/}
        {/*    </div>*/}
        {/*    <div className="form-group">*/}
        {/*        <label>Password</label>*/}
        {/*        <input type="password" name="password" autoComplete="current-password" />*/}
        {/*    </div>*/}
        {/*    <button className="btn-submit">Login</button>*/}
        {/*</form>*/}
      </div>
    </div>
  </div>
);

export default Modal;
