import ConnectToStravaButton from '../Strava/ConnectToStravaButton';

interface StravaConnectProps {
  url: string;
}

const StravaConnect = (props: StravaConnectProps) => (
  <>
    <ConnectToStravaButton url={props.url} />
  </>
);

export default StravaConnect;
