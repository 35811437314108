import { userActionTypes } from './actionTypes';
import { Dispatch } from 'redux';
import axios from '../../axios-skip';

const loginStart = () => {
  return {
    type: userActionTypes.LOGIN_START,
  };
};

const signupStart = () => {
  return {
    type: userActionTypes.SIGNUP_START,
  };
};

const doLogout = () => {
  console.log('in doLogout');
  localStorage.removeItem('accessToken');
  localStorage.removeItem('firstName');
  localStorage.removeItem('lastName');
  localStorage.removeItem('email');
  return {
    type: userActionTypes.LOGOUT,
  };
};

interface LoginSuccessResponse {
  jwt: string;
  first_name: string;
  last_name: string;
  email: string;
}

const loginSuccess = (data: LoginSuccessResponse) => {
  localStorage.setItem('accessToken', data['jwt']);

  // localStorage.setItem('userId', data['user']);
  localStorage.setItem('firstName', data['first_name']);
  localStorage.setItem('lastName', data['last_name']);
  localStorage.setItem('email', data['email']);

  return {
    type: userActionTypes.LOGIN_SUCCESS,
    // userId: data['user'],
    first_name: data['first_name'],
    last_name: data['last_name'],
    email: data['email'],
    // role: data['scope'][0],
  };
};

const signupSuccess = (data: LoginSuccessResponse) => {
  localStorage.setItem('accessToken', data['jwt']);

  localStorage.setItem('firstName', data['first_name']);
  localStorage.setItem('lastName', data['last_name']);
  localStorage.setItem('email', data['email']);

  return {
    type: userActionTypes.LOGIN_SUCCESS,
    first_name: data['first_name'],
    last_name: data['last_name'],
    email: data['email'],
  };
};

const loginError = (error: string) => {
  return {
    type: userActionTypes.LOGIN_ERROR,
    errorMessage: error,
  };
};

const signupError = (error: string) => {
  return {
    type: userActionTypes.SIGNUP_ERROR,
    errorMessage: error,
  };
};

export const login = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    // TODO type this
    dispatch(loginStart());
    try {
      const response = await axios.post('login', { email, password });
      dispatch(loginSuccess(response.data));
    } catch (e) {
      // @ts-ignore
      if (e.response && e.response.data.message) {
        // @ts-ignore
        dispatch(loginError(e.response.data.message));
      } else {
        dispatch(loginError('There was an error logging in, please try again'));
      }
    }
  };
};

export const signup = (email: string, password: string) => {
  return async (dispatch: Dispatch) => {
    dispatch(signupStart());
    try {
      const response = await axios.post('signup', { email, password });
      dispatch(signupSuccess(response.data));
    } catch (e: any) {
      if (e.response && e.response.data.detail) {
        dispatch(signupError(e.response.data.detail));
      } else {
        dispatch(
          signupError('There was an error logging in, please try again'),
        );
      }
    }
  };
};

export const getRefreshToken = () => {
  return async (dispatch: Dispatch) => {
    try {
      await axios.post('new-refresh');
      console.log('new r done')
    } catch (e: any) {
      console.log('new r failed')
    }
  }
}

export const logout = () => {
  console.log('in logout action');
  return async (dispatch: Dispatch) => {
    try {
      await axios.post('logout');
      dispatch(doLogout());
    } catch (e: any) {}
  };
};
