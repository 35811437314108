import React from 'react';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { applyMiddleware, combineReducers, compose, createStore } from 'redux';

import usersReducer from './store/reducers/users';
import chatsReducer from './store/reducers/chats';
import stravaConnectReducer from './store/reducers/stravaConnect';
import thunk from 'redux-thunk';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';

import * as Sentry from '@sentry/react';

import ReactGA from 'react-ga4';

ReactGA.initialize('G-5YB4SV8VP4');

Sentry.init({
  dsn: 'https://d9543c654376cd747a322cf002d4706b@o4506858171269120.ingest.us.sentry.io/4506858222845952',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/app\.teamskip\.ai/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const rootReducer = combineReducers({
  users: usersReducer,
  chats: chatsReducer,
  stravaConnect: stravaConnectReducer,
});

const store = createStore(rootReducer, compose(applyMiddleware(thunk)));

const root = createRoot(
  document.getElementById('root') as Element, // Use a type assertion to ensure it's not null
);

root.render(
  <Provider store={store}>
    <App />
  </Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
