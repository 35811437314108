import React from 'react';
import { IMessageType } from '../../../classes/IMessageType';
import moment from 'moment';
import StravaConnect from '../../Chats/StravaConnect';
import config from '../../../config';
import LoginButtons from '../../LoginButtons/LoginButtons';

const MARKDOWN_URL_REGEX = /\[([^\]]+)\]\(([^)]+)\)/g;

function linkifyUrls(markdown: string): string {
    if (markdown)
        return markdown.replace(MARKDOWN_URL_REGEX, '<a href="$2" target="_blank">$1</a>');
    return "";
}

interface LinkifiedTextProps {
    text: string;
}

const LinkifiedText: React.FC<LinkifiedTextProps> = ({ text }) => {
    const linkifiedText = linkifyUrls(text);

    return (
        <div dangerouslySetInnerHTML={{ __html: linkifiedText }} />
    );
};

const Message = (props: IMessageType) => {
  let timestamp;
  if (new Date(props.timestamp).toDateString() === new Date().toDateString()) {
    timestamp = moment(props.timestamp).fromNow();
  } else {
    timestamp = moment(props.timestamp).format('LT');
  }

  return (
    <>
      <span className={props.from === 'SKIP' ? 'ai shown' : 'human shown'}>
        <LinkifiedText text={props.message}/>
          {/*<>{props.message}</>*/}
      </span>
      <span
        className={props.from === 'SKIP' ? 'timestamp-left' : 'timestamp-right'}
      >
        {timestamp}
      </span>
      {props.special === 'STRAVA' ? (
        <span className="strava-button">
          <StravaConnect url={config.STRAVA_CALLBACK_URL} />
        </span>
      ) : null}
      {props.special === 'LOGIN_OR_SIGNUP' ? (
        <LoginButtons
          setShowSignupModal={props.setShowSignupModal}
          setShowLoginModal={props.setShowLoginModal}
        />
      ) : null}
    </>
  );
};

export default Message;
