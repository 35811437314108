import React from 'react';

interface LoginButtonProps {
  setShowLoginModal: () => void;
  setShowSignupModal: () => void;
}

const LoginButtons = (props: LoginButtonProps) => {
  const showSignupModal = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    props.setShowSignupModal();
  };

  const showLoginModal = (e: any) => {
    e.preventDefault();
    props.setShowLoginModal();
  };

  return (
    <span className="login-buttons">
      <button
        type="button"
        className="login-extra-button"
        onClick={showSignupModal}
      >
        Signup
      </button>
      <button className="login-extra-button" onClick={showLoginModal}>
        Login
      </button>
    </span>
  );
};

export default LoginButtons;
