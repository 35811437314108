import React, { useEffect, useState } from 'react';
import { IAppState } from '../../classes/IAppState';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as actionCreators from '../../store/actions';
import { connect } from 'react-redux';

import './chat.css';
import IChatDay from '../../classes/IChatDay';

import Messages from '../../components/Messages/Messages';
import useWebSocket from 'react-use-websocket';
import config from '../../config';
import Menu from '../../components/Menu/Menu';
import Modal from '../../components/Modal/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import Suggestions from '../../components/Suggestions/Suggestions';
import ReactGA from 'react-ga4';
import TarForm from "../../components/Forms/TarForm";

// polyfill();

interface ChatProps {
  loading: boolean;
  error: boolean;
  errorMessage: string;
  chats: IChatDay[];
  getChats: () => void;
  newRefresh: () => void;
  firstName: string;
  lastName: string;
  logout: () => void;
  loggedIn: boolean;
}





const Chat = (props: ChatProps) => {
  const [messages, setMessages] = useState<IChatDay[]>([]);
  const [message, setMessage] = useState('');
  const [showMenu, setShowMenu] = useState<boolean>(false);
  const [showSuggestions, setShowSuggestions] = useState<boolean>(false);
  const [showLoginModal, setShowLoginModal] = useState<boolean>(false);
  const [showSignupModal, setShowSignupModal] = useState<boolean>(false);
  const [showLoadingMessage, setShowLoadingMessage] = useState<boolean>(false);
  const [canSendMessage, setCanSendMessage] = useState<boolean>(false);
  const [maxRetriesHit, setMaxRetriesHit] = useState<boolean>(false);

  const WS_URL =
    config.BACKEND_WS_URL + `ws?token=${localStorage.getItem('accessToken')}`;

  const toggleLoginModal = () => {
    // console.log('here!')
    setShowLoginModal((old) => !old);
  };

  const toggleSignupModal = () => {
    setShowSignupModal((old) => !old);
  };

  const { getChats } = props;
  useEffect(() => {
    getChats();
  }, [getChats]);

  const queryParameters = new URLSearchParams(window.location.search);
  const showTarForm = queryParameters.get('tar') && messages.length === 1 && messages[0].data.length === 0;

  const location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  }, [location]);

  const { chats } = props;
  useEffect(() => {
    if (chats.length === 1 && chats[0].data.length > 0) {
      const lastChat = chats[0].data[chats[0].data.length - 1];
      if (
        lastChat.messageFrom === 'SKIP' &&
        lastChat.type === 'BACKDOOR_ONBOARDER' &&
        lastChat.message.toLowerCase().includes('strava')
      ) {
        const newData = [
          ...chats[0].data.slice(0, -1),
          { ...lastChat, special: 'STRAVA' },
        ];
        setMessages([{ ...chats[0], data: newData }]);
        return;
      }
    }
    setMessages(chats);
  }, [chats, setMessages]);

  useEffect(() => {
    // document.querySelector('.chat-finished')?.scrollIntoView({
    //   block: 'end', // "start" | "center" | "end" | "nearest",
    //   behavior: 'auto', //"auto"  | "instant" | "smooth",
    // });

    document.querySelector('.chat-finished')?.scrollIntoView({});
  }, [messages]);

  const navigate = useNavigate();
  useEffect(() => {
    if (!props.loggedIn && messages.length) {
      navigate(0);
    }
  }, [props.loggedIn, navigate, messages]);

  const handleRefresh = () => {
    navigate(0);
  };

  const handleMessageChange = (input: React.ChangeEvent<HTMLInputElement>) => {
    setMessage(input.target.value);
  };

  const addMessage = () => {
    if (message.length) {
      sendMessage(message);
      setShowLoadingMessage(true);
      setMessage('');
    }
  };

  const addMessageEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      addMessage();
      event.preventDefault();
    }
  };

  const toggleMenuVisibility = () => {
    setShowMenu((old) => !old);
  };

  const toggleSuggestionsVisibility = () => {
    setShowSuggestions((old) => !old);
  };

  const sendMessageFromSuggestions = (message: string, autoSend: boolean) => {
    if (autoSend) {
      sendMessage(message);
      setShowLoadingMessage(true);
      setMessage('');
    } else {
      setMessage(message);
    }
  };

  // const navigate = useNavigate();
  const logoutPressed = async () => {
    console.log('logout pressed');
    props.logout();
  };

  const {
    sendMessage,
    // sendJsonMessage,
    lastMessage,
    // lastJsonMessage,
    // readyState,
    // getWebSocket,
  } = useWebSocket(WS_URL, {
    onOpen: () => {
      console.log('opened');
      setCanSendMessage(true);
    },
    shouldReconnect: (closeEvent) => true,
    reconnectAttempts: 10,
    reconnectInterval: (attemptNumber) =>
      Math.min(Math.pow(2, attemptNumber) * 1000, 10000),
    onReconnectStop: (numAttempts) => {
      setMaxRetriesHit(true);
    },
    onClose: () => {
      console.log('closed');
      setShowLoadingMessage(false);
      setCanSendMessage(false);
    },
  });

  const { newRefresh } = props;
  useEffect(() => {
    newRefresh();
  }, [newRefresh])

  useEffect(() => {
    if (lastMessage !== null) {
      const messageData = JSON.parse(lastMessage.data);
      // If the message contains a JWT then its a login request, need to set the
      // token and refresh
      if (messageData.jwt && messageData.jwt.length) {
        localStorage.setItem('accessToken',messageData.jwt);
        // Update refresh token
        navigate(0);
      }
      if (messageData.from === 'SKIP') {
        setShowLoadingMessage(false);
      }
      setMessages((prev) => {
        const finalMessage = prev.slice(-1)[0].data.slice(-1)[0];
        if (!finalMessage) {
          // There is no previous message, so just set the new one
          return [
            ...prev.slice(0, prev.length - 1),
            {
              day: prev[prev.length - 1].day,
              data: [
                ...prev[prev.length - 1].data,
                {
                  id: 'None',
                  timestamp: new Date(),
                  type: 'chat',
                  messageFrom: messageData.from,
                  message: messageData.message,
                  special: messageData.special,
                },
              ],
            },
          ];
        } else if (
          finalMessage.isStream &&
          !finalMessage.streamFinished &&
          messageData.type !== 'stream'
        ) {
          // The previous message is an unfinished stream and the new one is not, place the new one above it
          return [
            ...prev.slice(0, prev.length - 1),
            {
              day: prev[prev.length - 1].day,
              data: [
                ...prev[prev.length - 1].data.slice(
                  0,
                  prev[prev.length - 1].data.length - 1,
                ),
                {
                  id: 'None',
                  timestamp: new Date(),
                  type: 'chat',
                  messageFrom: messageData.from,
                  message: messageData.message,
                  special: messageData.special,
                },
                {
                  ...prev[prev.length - 1].data.slice(-1)[0],
                },
              ],
            },
          ];
        } else if (
          messageData.type === 'stream' &&
          messageData.from === 'SKIP' &&
          finalMessage.messageFrom === 'SKIP' &&
          finalMessage.isStream &&
          messageData.message === null
        ) {
          // The stream is finished, mark it as finished.
          return [
            ...prev.slice(0, prev.length - 1),
            {
              day: prev[prev.length - 1].day,
              data: [
                ...prev[prev.length - 1].data.slice(
                  0,
                  prev[prev.length - 1].data.length - 1,
                ),
                {
                  ...prev[prev.length - 1].data.slice(-1)[0],
                  streamFinished: true,
                },
              ],
            },
          ];
        } else if (
          messageData.type === 'stream' &&
          messageData.from === 'SKIP' &&
          finalMessage.messageFrom === 'SKIP' &&
          finalMessage.isStream
        ) {
          // New message is a stream, continue adding it to the last message
          return [
            ...prev.slice(0, prev.length - 1),
            {
              day: prev[prev.length - 1].day,
              data: [
                ...prev[prev.length - 1].data.slice(
                  0,
                  prev[prev.length - 1].data.length - 1,
                ),
                {
                  id: 'None',
                  timestamp: new Date(),
                  type: 'chat',
                  messageFrom: 'SKIP',
                  message:
                    prev[prev.length - 1].data.slice(-1)[0].message +
                    messageData.message,
                  special: messageData.special,
                  streamFinished: false,
                  isStream: true,
                },
              ],
            },
          ];
        } else {
          // Just add as a new message underneath
          return [
            ...prev.slice(0, prev.length - 1),
            {
              day: prev[prev.length - 1].day,
              data: [
                ...prev[prev.length - 1].data,
                {
                  id: 'None',
                  timestamp: new Date(),
                  type: 'chat',
                  messageFrom: messageData.from,
                  message: messageData.message,
                  special: messageData.special,
                  isStream: messageData.type === 'stream',
                },
              ],
            },
          ];
        }
      });
    }
  }, [lastMessage, setMessages, navigate]);

  return (
    <>
      <div className="header-area">
        <button className="open-menu" onClick={toggleMenuVisibility}></button>
        {/*<button className="sound-off"></button>*/}
      </div>

      <div className="chat-area">
        <div className="scrollable-area" id="aichat">
          <div className="ai-chat">
            {showTarForm && canSendMessage ?
                <TarForm sendMessage={sendMessage}/>
            :<Messages
              data={messages}
              toggleLoginModal={toggleLoginModal}
              toggleSignupModal={toggleSignupModal}
              showLoader={showLoadingMessage}
            />}
          </div>
          <div className="chat-finished" id="chat-finished"></div>
        </div>
      </div>

      {showMenu ? (
        <Menu toggleMenu={toggleMenuVisibility} logout={logoutPressed} />
      ) : null}
      {showSuggestions ? (
        <Suggestions
          toggleShow={toggleSuggestionsVisibility}
          sendMessage={sendMessageFromSuggestions}
        />
      ) : null}
      {showLoginModal ? <Modal type={'LOGIN'} /> : null}
      {showSignupModal ? <Modal type={'SIGNUP'} /> : null}

      {!showTarForm?
        <div className="bottom-area">
        {maxRetriesHit ? (
          <div className="reconnect-area" onClick={handleRefresh}>
            Reconnect
          </div>
        ) : (
          <div className="chat-input">
            <button
              className="topics-popup-show"
              onClick={toggleSuggestionsVisibility}
            >
              S
            </button>
            <div className="chat-input-field">
              <input
                id="send-message-input"
                type="text"
                value={message}
                onChange={handleMessageChange}
                onKeyDown={addMessageEnter}
                placeholder="Talk with Skip"
                className="input-chat"
              />
            </div>
            <button
              className={
                canSendMessage
                  ? 'chat-button-send'
                  : 'chat-button-send-disabled'
              }
              id="send-message-btn"
              onClick={addMessage}
              disabled={!canSendMessage}
            >
              Send
            </button>
          </div>
        )}

        <div className="terms">
          By messaging with TeamSkip, you are agreeing to our{' '}
          <a href="/terms-of-service">Terms of Service</a> and{' '}
          <a href="/privacy-policy">Privacy Policy</a>
        </div>
      </div>:null}
    </>
  );
};

const mapStateToProps = (state: IAppState) => {
  return {
    loading: state.chats.loading,
    error: state.chats.error,
    errorMessage: state.chats.errorMessage,
    chats: state.chats.chats,
    firstName: state.users.firstName,
    lastName: state.users.lastName,
    loggedIn: state.users.loggedIn,
  };
};

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => {
  return {
    getChats: () => dispatch(actionCreators.getChats()),
    logout: () => dispatch(actionCreators.logout()),
    newRefresh: () => dispatch(actionCreators.getRefreshToken())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
