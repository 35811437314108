import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.css';
import { IAppState } from './classes/IAppState';
import { connect } from 'react-redux';
import StravaCallback from './containers/StravaCallback/StravaCallback';
import Chat from './containers/Chat/Chat';

interface AppProps {
  loggedIn: boolean;
}

function App(props: AppProps) {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Chat />} />
        <Route path="/stravaCallback" element={<StravaCallback />} />
      </Routes>
    </Router>
  );
}

const mapStateToProps = (state: IAppState) => {
  return {
    loggedIn: state.users.loggedIn,
  };
};
export default connect(mapStateToProps)(App);
